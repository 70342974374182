<template>
	<div class="video-demo">
		<h2>Chemformation Tutorial</h2>

		<video autoplay controls class="responsive-video">
			<source src="@/assets/ChemformationTutorial.mp4" type="video/mp4" />
			Your browser does not support the video tag.
		</video>
	</div>
</template>

<script>
export default {
	name: "VideoDemo",
};
</script>

<style lang="scss" scoped>
.video-demo {
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;

	h2 {
		margin: 1.5rem 0;
	}

	.responsive-video {
		width: 95%;
		height: auto;
	}
}
</style>
